import {
  type CurrentSportYearRecord,
  type MenuResponse,
  rdbV1DefaultYearList,
  rdbV1FiltersSportsList,
  type SlimSiteResponse,
  type SportFilter,
  v1SitesDetail,
  v1SitesSlimSitesList,
  v1SitesSubConfigsDetail,
} from '@on3/api';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import { getSiteChecks, getSiteMenus } from '@on3/ui-lib/utils/sites';
import type { NextComponentType, NextPageContext } from 'next';

import type { IGenericPageProps } from '../api/schema/custom-contracts';
import type { SiteServerProps } from '../contexts/SiteProvider';
import type { NextApiParams } from '../types/next';
import { filterRankingSports } from '../utils/filterRankingSports';

const withValidUrl = (
  WrappedComponent: NextComponentType<NextPageContext, unknown, any>,
) =>
  function WithValidUrl(props: IGenericPageProps) {
    const currentSite = props?.siteData?.currentSite;

    if (currentSite) {
      return <WrappedComponent {...props} />;
    }

    return null;
  };

const CURRENT_YEAR = new Date().getFullYear();
const NEXT_YEAR = CURRENT_YEAR + 1;

const DEFAULT_YEARS: CurrentSportYearRecord = {
  footballRecruitingYear: NEXT_YEAR,
  nonFootballRecruitingYear: NEXT_YEAR,
  footballSeasonYear: CURRENT_YEAR,
  nonFootballSeasonYear: CURRENT_YEAR,
  transferYear: NEXT_YEAR,
  nbaDraftYear: CURRENT_YEAR,
  nflDraftYear: CURRENT_YEAR,
  nilYear: CURRENT_YEAR,
};

withValidUrl.isValid = async (ctx: NextApiParams, siteKey?: number | null) => {
  try {
    const siteUrls = await v1SitesSlimSitesList(proxyApi, {});

    // Default to On3
    let slimSite = siteUrls.find((url: SlimSiteResponse) => url.key === 44);

    if (siteKey) {
      slimSite = siteUrls.find((url: SlimSiteResponse) => url.key === siteKey);
    } else {
      if (ctx?.query?.team) {
        slimSite = siteUrls?.find(
          (url: SlimSiteResponse) =>
            url.url?.toLowerCase() ===
            `/teams/${(ctx?.query?.team as string)?.toLowerCase()}`,
        );
      } else if (ctx?.query?.site) {
        slimSite = siteUrls?.find(
          (url: SlimSiteResponse) =>
            url.url?.toLowerCase() ===
            `/sites/${(ctx?.query?.site as string)?.toLowerCase()}`,
        );
      } else if (ctx?.query?.college) {
        slimSite = siteUrls?.find(
          (url: SlimSiteResponse) =>
            url.url?.toLowerCase() ===
            `/college/${(ctx?.query?.college as string).toLowerCase()}`,
        );
      }
    }

    if (!slimSite?.key || !slimSite?.live) {
      console.error({ url: ctx?.req?.url, error: 'Valid Url Check: line 62' });

      return null;
    }

    const [currentSiteResult, siblingSiteResult] = await Promise.allSettled([
      v1SitesDetail(proxyApi, { key: slimSite.key }),
      slimSite.siblingSiteKey
        ? v1SitesDetail(proxyApi, { key: slimSite.siblingSiteKey })
        : Promise.resolve(null),
    ]);

    const currentSite = getFulfilledValue(currentSiteResult);
    const siblingSite = getFulfilledValue(siblingSiteResult);

    if (!currentSite) {
      console.error({
        url: ctx?.req?.url,
        error: `Current site failed ${slimSite.key} `,
      });

      return null;
    }

    const siteMenus: (Promise<MenuResponse[]> | null)[] = getSiteMenus(
      currentSite,
      ctx,
    );
    const { key, allowSubs, siblingSiteKey } = currentSite;

    const subConfigKey = allowSubs ? key : siblingSiteKey || 44;
    const [primaryMenu, secondaryMenu, sportsData, defaultYears, subConfig] =
      await Promise.allSettled([
        siteMenus[0],
        siteMenus[1],
        rdbV1FiltersSportsList(proxyApi),
        rdbV1DefaultYearList(proxyApi),
        v1SitesSubConfigsDetail(proxyApi, subConfigKey),
      ]);

    const sportsDataResponse: SportFilter[] =
      getFulfilledValue(sportsData) || [];

    const rankingSports = filterRankingSports(sportsDataResponse) || [];

    const scheduleSports = filterRankingSports(sportsDataResponse) || [];

    const baseball = sportsDataResponse.find((s) => s?.key === 4);

    if (baseball) {
      scheduleSports?.push(baseball);
    }

    // Cancer
    const siteChecks = getSiteChecks({
      site: currentSite,
      pathname: ctx.pathname ?? currentSite.url ?? '',
      query: ctx.query ?? {},
    });

    let secondaryMenuData = getFulfilledValue(secondaryMenu);

    if (siteChecks.isTeam || siteChecks.isPro || siteChecks.isOn3) {
      secondaryMenuData = secondaryMenuData?.splice(0, 6) ?? [];
    }
    // End Cancer

    const years = getFulfilledValue(defaultYears);
    const newYears: CurrentSportYearRecord = { ...DEFAULT_YEARS, ...years };

    const data: SiteServerProps = {
      currentSite,
      siblingSite,
      siteUrls,
      menuData: getFulfilledValue(primaryMenu),
      secondaryMenuData,
      sportsData: sportsDataResponse,
      rankingSports,
      scheduleSports,
      defaultYears: newYears,
      subConfig: getFulfilledValue(subConfig),
    };

    return data;
  } catch {
    return null;
  }
};

export default withValidUrl;
export { withValidUrl };
